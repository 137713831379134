import React, { useState } from "react"
import { Drawer, Form, Row, Col, Button, Space, Typography, DatePicker } from "antd"
import { Casselect, MyInput } from '../../../components';
import { CalendarOutlined, PercentageOutlined } from "@ant-design/icons";

const { Text } = Typography

const AgentDrawer = ({ visible, onClose, agentedit }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    
    return (
        <div>
            <Drawer
                title={<Text className='text-white'>{agentedit? 'Edit Agent' : 'Add Agent'}</Text>}
                onClose={onClose}
                className="card-bg"
                open={visible}
                width={700}
                footer={
                    <div className="w-100">
                        <Space className="w-100">
                            <Button
                                onClick={onClose}
                                className="btn-cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                block
                                type="primary"
                                loading={loading}
                                htmlType="submit"
                                className="bg-gradient border0"
                                onClick={()=>form.submit()}
                            >
                                {
                                    agentedit ? 'Update' : 'Save'
                                }
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='firstName'
                                label='First name'
                                required
                                message='Please enter first name'
                                value={form.getFieldValue("firstName") || ''} 
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='midName'
                                label='Middle name'
                                value={form.getFieldValue("midName") || ''} 
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='lastName'
                                label='Last name'
                                required
                                message='Please enter last name'
                                value={form.getFieldValue("lastName") || ''} 
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='userName'
                                label='Username'
                                required
                                message='Please enter username'
                                value={form.getFieldValue("userName") || ''} 
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='password'
                                type='password'
                                label='Password'
                                required
                                message='Please enter password'
                                className='bg-main border0'
                                value={form.getFieldValue("password") || ''} 
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <Form.Item
                                name='dob'
                                label={<Text className='text-white'>DoB</Text>}
                                rules={[
                                    {
                                        required:true,
                                        message:'Please enter dob'
                                    }
                                ]}
                                >
                                <DatePicker 
                                    className='bg-main border0 w-100 searchinput pad-input'
                                    value={form.getFieldValue("dob") || ''} 
                                    allowClear={false}
                                    inputReadOnly
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <Casselect
                                label= 'Gender'
                                name="gender" 
                                required
                                message='Please choose gender'
                                value={form.getFieldValue("gender")}
                                className='text-white'
                                options={[
                                    {name:"Male", id:'male'},
                                    {name:"Female", id:'female'},
                                    {name:"Other", id:'other'},
                                ]}
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <Casselect
                                label= 'City manager'
                                name="citymanager" 
                                required
                                message='Please choose city manager'
                                value={form.getFieldValue("citymanager")}
                                className='text-white'
                                options={[
                                    {name:"Shujat", id:'shujat'},
                                    {name:"Sheraz", id:'sheraz'},
                                    {name:"Ali", id:'ali'},
                                ]}
                            />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <Casselect
                                label= 'Master agent'
                                name="masterAgent" 
                                required
                                message='Please choose master agent'
                                value={form.getFieldValue("masterAgent")}
                                className='text-white'
                                options={[
                                    {name:"Shujat", id:'shujat'},
                                    {name:"Sheraz", id:'sheraz'},
                                    {name:"Ali", id:'ali'},
                                ]}
                            />
                        </Col>
                        
                        <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                            <MyInput
                                name='commissionRate'
                                type='number'
                                label='Commission rate'
                                required
                                message='Please enter commission rate'
                                suffix={<PercentageOutlined className="text-white" />}
                                className='bg-main border0'
                                value={form.getFieldValue("commissionRate") || ''} 
                            />
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export {AgentDrawer}