import React from 'react'
import { Button, Row, Col, Form, Typography, Table } from 'antd'
import { ModuleTopHeading } from '../../../../components';

const { Text } = Typography

const AllDownlines = () => {

    const [form] = Form.useForm();
  
  
      const columns = [
          {
              title: <Text className='text-white'>ID</Text>,
              dataIndex: 'id',
              width:250
          },
          {
              title: <Text className='text-white'>Name</Text>,
              dataIndex: 'name',
              width:250
          },
          {
            title: <Text className='text-white'>Downline type</Text>,
            dataIndex: 'downlineType',
            width:250
        },
      ];
  
      const data = [
          {
              key: '1',
              id: <Text className='text-white'>#1</Text>,
              name: <Text className='text-white'>Sher Ali</Text>,
              downlineType: <Text className='text-white'>Agent</Text>,
          },
          {
              key: '2',
              id: <Text className='text-white'>#2</Text>,
              name: <Text className='text-white'>Bilal</Text>,
              downlineType: <Text className='text-white'>Player</Text>,
          },
          {
              key: '3',
              id: <Text className='text-white'>#3</Text>,
              name: <Text className='text-white'>Sheraz</Text>,
              downlineType: <Text className='text-white'>Agent</Text>,
          },
          {
              key: '4',
              id: <Text className='text-white'>#4</Text>,
              name: <Text className='text-white'>Ali</Text>,
              downlineType: <Text className='text-white'>Agent</Text>,
          },
          {
              key: '5',
              id: <Text className='text-white'>#5</Text>,
              name: <Text className='text-white'>Ahmed</Text>,
              downlineType: <Text className='text-white'>Player</Text>,
          },
        ];
  
    return (
        <div>
            <Row gutter={[24,24]}>
                <Col xs={24} sm={24} md={12} lg={18}>
                    <ModuleTopHeading
                        name='Agent Downlines'
                    />
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 500}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export {AllDownlines}