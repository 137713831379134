import React from 'react'
import { Flex } from 'antd'
import { CitymanagerDetailTable } from '../../components'

const CityManager = () => {

  return (
    <Flex gap={20} vertical>
        <CitymanagerDetailTable />
    </Flex>
  )
}

export {CityManager}