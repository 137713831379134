import React from 'react'
import { AppTable, CardComponent, ComissionSummaryComponent } from '../../components'
import { Flex } from 'antd'

const Dashboard = () => {
  return (
    <Flex vertical gap={20}>
        <CardComponent />
        <AppTable />
        <ComissionSummaryComponent />
    </Flex>
  )
}

export {Dashboard}