import React from 'react'
import { MasteragentDetailTable } from '../../components'
import { Flex } from 'antd'

const MasterAgent = () => {

    return (
        <Flex gap={20} vertical>
            <MasteragentDetailTable />
        </Flex>
    )
}

export {MasterAgent}