import React from 'react'
import { AgentBalanceManagement, AgentDetailTable, AgentTransaction } from '../../components'
import { Flex } from 'antd'

const Agent = () => {

    return (
        <Flex gap={20} vertical>
            <AgentDetailTable />
            {/* <AgentBalanceManagement /> */}
        </Flex>
    )
}

export {Agent}