import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Card, Table, Flex, Space, Switch, Dropdown } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, AddCityManagerDrawer, MyInputnoLabel, ViewCityManagerDetailDrawer, ApplyCitymanagerModal } from '../../../components';
import { EditOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';

const { Text } = Typography

const CitymanagerDetailTable = () => {
    const [form] = Form.useForm();
    const [ visible, setVisible ]= useState(false);
    const [ manageredit, setManagerEdit ] = useState(null)
    const [ viewvisible, setViewVisible ] = useState(false)
    const [ applyentity, setApplyEntity] = useState(false)

    const onChange = (checked) => {
        if(!checked){
            setApplyEntity(true)
        }
        console.log(`switch to ${checked}`);
    };
    const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];

    const columns = [
        {
            title: <Text className='text-white'>ID</Text>,
            dataIndex: 'id',
            width:100
        },
        {
            title: <Text className='text-white'>Name</Text>,
            dataIndex: 'name',
        },
        {
            title: <Text className='text-white'>Username</Text>,
            dataIndex: 'userName',
        },
        {
            title: <Text className='text-white'>DoB</Text>,
            dataIndex: 'dob',
        },
        {
            title: <Text className='text-white'>Gender</Text>,
            dataIndex: 'gender',
        },
        {
            title: <Text className='text-white'>Commission rate</Text>,
            dataIndex: 'commissionRate',
        },
        {
            title: <Text className='text-white'>Time stamp</Text>,
            dataIndex: 'timeStamp',
            width:250
        },
        {
            title: <Text className='text-white'>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View master agent'
                        shape="circle"
                        icon={<EyeOutlined className='text-white' />}
                        onClick={() => { setViewVisible(true) }}
                        ghost
                        className='borderwhite'
                    />
                    <ActionButton
                        title='Edit manager'
                        shape="circle"
                        icon={<EditOutlined className='text-white' />}
                        onClick={() => { setVisible(true);setManagerEdit(row?.cityManagerId)}}
                        ghost
                        className='borderwhite'
                    />
                    <Switch defaultChecked size='small' onChange={onChange} />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            id: <Text className='text-white'>01</Text>,
            name: <Text className='text-white'>Zahid Imam</Text>,
            userName: <Text className='text-white'>zahid@gmail</Text>,
            dob: <Text className='text-white'>12/12/2000</Text>,
            gender: <Text className='text-white'>male</Text>,
            commissionRate: <Text className='text-white'>12%</Text>,
            timeStamp: <Text className='text-white'>21/04/2024 04:00 PM</Text>,
        },
        {
            key: '2',
            id: <Text className='text-white'>02</Text>,
            name: <Text className='text-white'>Waseem Khan</Text>,
            userName: <Text className='text-white'>waseem@gmail</Text>,
            dob: <Text className='text-white'>12/12/2000</Text>,
            gender: <Text className='text-white'>male</Text>,
            commissionRate: <Text className='text-white'>12%</Text>,
            timeStamp: <Text className='text-white'>22/06/2024 12:00 PM</Text>,
        },
        {
            key: '3',
            id: <Text className='text-white'>03</Text>,
            name: <Text className='text-white'>Sheraz</Text>,
            userName: <Text className='text-white'>sheraz@gmail</Text>,
            dob: <Text className='text-white'>12/12/2000</Text>,
            gender: <Text className='text-white'>male</Text>,
            commissionRate: <Text className='text-white'>12%</Text>,
            timeStamp: <Text className='text-white'>17/05/2024 08:00 PM</Text>,
        },
      ];

  return (
    <div>
        <Card className='card-bg rounded border0'>
            <Row gutter={[24,24]}>
                <Col xs={24} sm={24} md={12} lg={18}>
                    <ModuleTopHeading
                        name='City Manager'
                        onClick={()=>{setVisible(true)}}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Flex justify='end' gap={10}>
                            <MyInputnoLabel
                                name='name'
                                label='Search'
                                placeholder='Search here ...'
                                value={form.getFieldValue("name") || ''} 
                                className='w-100'
                                onChange={(e)=>e.target.value}
                            />
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                mode
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                            >
                                <Button
                                    icon={<FilterOutlined />}
                                    className='bg-main border0 text-white btn-filter'
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                        </Flex>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        scroll={{x: 1000}}
                        className='table-dark pagination'
                        rowHoverable={false}
                        pagination={{
                            hideOnSinglePage: true,
                            total: 12,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            // size: "default",
                            // pageSizeOptions: ['10', '20', '50', '100'],
                            // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                        }}
                    />
                </Col>
            </Row>
            <AddCityManagerDrawer
                visible={visible}
                manageredit={manageredit}
                onClose={()=> {setVisible(false);setManagerEdit(null)}}
            />
            <ViewCityManagerDetailDrawer 
                viewvisible={viewvisible}
                onClose={()=>setViewVisible(false)}
            />
            
            <ApplyCitymanagerModal 
                applyentity={applyentity}
                onClose={()=>setApplyEntity(false)}
            />
        </Card>
    </div>
  )
}

export {CitymanagerDetailTable}