import { PlusOutlined } from "@ant-design/icons"
import { Row, Col, Space, Button, Typography } from "antd"

const { Title } = Typography
export const ModuleTopHeading=({name, onClick})=>{
    return (
        <Row>
            <Col span={24}>
                <Space className="align-center">
                    <Title level={3} className="my-0 text-white">
                        {
                            name
                        }
                    </Title>
                    {
                        onClick ?
                        <Button 
                            type="primary" 
                            shape="circle" 
                            size='small' 
                            className="center bg-gradient border0"
                            icon={<PlusOutlined/>}
                            onClick={onClick}
                        />
                        :<></>
                    }
                </Space>
            </Col>
        </Row>
    )
}