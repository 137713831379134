import React, { useState } from 'react'
import { Drawer, Form, Row, Col, Typography, Card, Space, Image, DatePicker } from "antd"
import { Casselect, MyInput } from '../../../components';
import { TransactionSpecificeAgent } from './TransactionSpecificeAgent';
import { PercentageOutlined } from '@ant-design/icons';
import { AllDownlines } from './AllDownlines';

const { Title,Text } = Typography

const ViewCityManagerDetailDrawer = ({viewvisible,onClose}) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    
    return (
        <div>
            <Drawer
                title={<Text className='text-white'>View Agent</Text>}
                onClose={onClose}
                className="card-bg"
                open={viewvisible}
                width={900}
                footer={false}
            >
                <Row gutter={[24,24]}>
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onFinish}
                        >
                            <Row gutter={16}>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='firstName'
                                        label='First name'
                                        placeholder=' jhon'
                                        value={form.getFieldValue("firstName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='midName'
                                        label='Middle name'
                                        placeholder=' jhon'
                                        value={form.getFieldValue("midName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='lastName'
                                        label='Last name'
                                        placeholder=' jhon'
                                        value={form.getFieldValue("lastName") || ''} 
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='userName'
                                        label='Username'
                                        placeholder=' jhon89'
                                        value={form.getFieldValue("userName") || ''}
                                        disabled 
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='password'
                                        type='password'
                                        label='Password'
                                        className='bg-main border0'
                                        placeholder=' A@123456'
                                        value={form.getFieldValue("password") || ''}
                                        disabled 
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <Form.Item
                                        name='dob'
                                        label={<Text className='text-white'>DoB</Text>}
                                        >
                                        <DatePicker 
                                            className='bg-main border0 w-100 searchinput pad-input'
                                            placeholder=' 12/12/2000'
                                            value={form.getFieldValue("dob") || ''} 
                                            allowClear={false}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <Casselect
                                        label= 'Gender'
                                        name="gender" 
                                        placeholder='male'
                                        value={form.getFieldValue("gender")}
                                        className='text-white'
                                        options={[
                                            {name:"Male", id:'male'},
                                            {name:"Female", id:'female'},
                                            {name:"Other", id:'other'},
                                        ]}
                                        disabled
                                    />
                                </Col>
                                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                                    <MyInput
                                        name='commissionRate'
                                        type='number'
                                        label='Commission rate'
                                        placeholder=' 1200%'
                                        suffix={<PercentageOutlined className="text-white" />}
                                        className='bg-main border0'
                                        value={form.getFieldValue("commissionRate") || ''} 
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Card className='bg-main border0 rounded height-100'>
                            <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                                <div className='my-2'>
                                    <Text className='text-white' strong>Commission</Text>
                                    <Title level={3} className='text-white my-0'>0.00 Peso</Title>
                                </div>
                                <Image src='/assets/icons/win.png' width={'40px'} />
                            </Space>
                        </Card>        
                    </Col>
                    <Col span={24}>
                        <AllDownlines />            
                    </Col>
                    <Col span={24}>
                        <TransactionSpecificeAgent />            
                    </Col>                    
                </Row>
            </Drawer>
        </div>
    )
}

export {ViewCityManagerDetailDrawer}