import { Flex } from 'antd'
import React from 'react'
import { PlayerBalanceManagement, PlayerDetailTable, PlayerTransaction } from '../../components'

const Players = () => {

  
    return (
        <Flex gap={20} vertical>
            <PlayerDetailTable/>
            {/* <PlayerTransaction />
            <PlayerBalanceManagement /> */}
        </Flex>
    )
}

export {Players}