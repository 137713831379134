import React,{ useState } from 'react'
import { Button, Row, Col, Form, Typography, Card, Table, Flex, Space, Switch, Dropdown } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, AlertModal, AddMasteragentDrawer, MyInputnoLabel, ApplyCitymanagerModal, ViewmasterAgentDetailDrawer } from '../../../components'
import { CreditCardOutlined, EditOutlined, EyeOutlined, FilterOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'

const { Text } = Typography

const MasteragentDetailTable = () => {

    const [form] = Form.useForm()
    const [ visible, setVisible ]= useState(false)
    const [ visiblemodal, setVisibleModal ] = useState(false)
    const [ masteredit, setMasterEdit ] = useState(null)
    const [ viewvisible, setViewVisible ] = useState(false)
    const [ applyentity, setApplyEntity] = useState(false)

    const onChange = (checked) => {
        if(!checked){
            setApplyEntity(true)
        }
        console.log(`switch to ${checked}`);
    };

    const items = [
        {
            label: <a href="#" className='text-white'>A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#" className='text-white'>Z-A</a>,
            key: '1',
        },
    ];
  
    const columns = [
        {
            title: <Text className='text-white'>ID</Text>,
            dataIndex: 'masterAgentId',
        },
        {
            title: <Text className='text-white'>Name</Text>,
            dataIndex: 'name',
        },
        {
            title: <Text className='text-white'>Username</Text>,
            dataIndex: 'userName',
        },
        {
            title: <Text className='text-white'>DoB</Text>,
            dataIndex: 'dob',
        },
        {
            title: <Text className='text-white'>Gender</Text>,
            dataIndex: 'gender',
        },        
        {
            title: <Text className='text-white'>Comission rate</Text>,
            dataIndex: 'comissionRate',
        },
        {
            title: <Text className='text-white'>Time stamp</Text>,
            dataIndex: 'timeStamp',
            width:200
        },
        {
            title: <Text className='text-white'>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 220,
            render: (_, row) => (
                <Flex gap={'small'} align='center'>
                    <ActionButton
                        title='View master agent'
                        shape="circle"
                        icon={<EyeOutlined className='text-white' />}
                        onClick={() => { setViewVisible(true) }}
                        ghost
                        className='borderwhite'
                    />
                    <ActionButton
                        title='Edit master agent'
                        shape="circle"
                        icon={<EditOutlined className='text-white' />}
                        onClick={() => { setVisible(true);setMasterEdit(row?.masterAgentId)}}
                        ghost
                        className='borderwhite'
                    />
                    <Switch defaultChecked size='small' onChange={onChange} />
                </Flex>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            masterAgentId: <Text className='text-white'>01</Text>,
            name: <Text className='text-white'>Zahid Imam</Text>,
            userName: <Text className='text-white'>zahid@gmail</Text>,
            dob: <Text className='text-white'>25</Text>,
            gender: <Text className='text-white'>male</Text>,
            comissionRate: <Text className='text-white'>12%</Text>,
            timeStamp: <Text className='text-white'>21/04/2024 04:00 PM</Text>,
        },
        {
            key: '2',
            masterAgentId: <Text className='text-white'>02</Text>,
            name: <Text className='text-white'>Waseem Khan</Text>,
            userName: <Text className='text-white'>waseem@gmail</Text>,
            dob: <Text className='text-white'>27</Text>,
            gender: <Text className='text-white'>male</Text>,
            comissionRate: <Text className='text-white'>12%</Text>,
            timeStamp: <Text className='text-white'>21/12/2023 09:00 PM</Text>,
        },
        {
            key: '3',
            masterAgentId: <Text className='text-white'>03</Text>,
            name: <Text className='text-white'>Sheraz</Text>,
            userName: <Text className='text-white'>sheraz@gmail</Text>,
            dob: <Text className='text-white'>A@123456</Text>,
            gender: <Text className='text-white'>male</Text>,
            comissionRate: <Text className='text-white'>12%</Text>,
            timeStamp: <Text className='text-white'>28/06/2024 03:00 PM</Text>,
        },
      ];
  
    return (
        <div>
            <Card className='card-bg rounded border0'>
                <Row gutter={[24,24]}>
                    <Col xs={24} sm={24} md={12} lg={18}>
                        <ModuleTopHeading
                            name='Master Agents'
                            onClick={()=>{setVisible(true)}}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6}>
                        <Form
                            form={form}
                            layout="vertical"
                        >
                            <Flex justify='end' gap={10}>
                                <MyInputnoLabel
                                    name='name'
                                    label='Search'
                                    placeholder='Search here ...'
                                    value={form.getFieldValue("name") || ''} 
                                    className='w-100'
                                    onChange={(e)=>e.target.value}
                                />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    mode
                                    trigger={['click']}
                                    arrow
                                    icon={<FilterOutlined />}
                                >
                                    <Button
                                        icon={<FilterOutlined />}
                                        className='bg-main border0 text-white btn-filter'
                                    >
                                        Filter
                                    </Button>
                                </Dropdown>
                            </Flex>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size='large'
                            columns={columns} 
                            dataSource={data} 
                            scroll={{x: 1000}}
                            className='table-dark pagination'
                            rowHoverable={false}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='bg-gradient border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
                <AddMasteragentDrawer
                    visible={visible}
                    masteredit={masteredit}
                    onClose={()=> {setVisible(false);setMasterEdit(null)}}
                />
                <ApplyCitymanagerModal 
                    applyentity={applyentity}
                    onClose={()=>setApplyEntity(false)}
                />

                <ViewmasterAgentDetailDrawer 
                    viewvisible={viewvisible}
                    onClose={()=>setViewVisible(false)}
                />
                <AlertModal 
                    visiblemodal={visiblemodal}
                    onClose={()=>setVisibleModal(false)}
                />
            </Card>
        </div>
    )
}

export {MasteragentDetailTable}