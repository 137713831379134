import React from 'react';
import { ConfigProvider, theme } from 'antd';
import RouteF from './RouteF';
import { BrowserRouter } from 'react-router-dom';

function App() {


  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorError: '#C30010',
            
          },
        }}
      >
        <RouteF />
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
