import React from 'react'
import { Button, Col, Flex, Form, Row } from 'antd'
import { MyInput } from '../../Forms'

const ChangePassword = () => {

    const [form] = Form.useForm()

  return (
    <div>
        <Form
            layout="vertical"
            form={form}
            initialValues={{
                remember: true,
            }}
            onFinish={()=>{}}
        >
            <Row gutter={16}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        name='currentPass'
                        label='Current password'
                        type='password'
                        required
                        message='Please enter your current password'
                        className='bg-main border0'
                        value={form.getFieldValue("currentPass") || ''} 
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        name='newPass'
                        label='New password'
                        type='password'
                        required
                        message='Please enter your new password'
                        className='bg-main border0'
                        value={form.getFieldValue("newPass") || ''}
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <MyInput
                        name='confirmPass'
                        label='Confirm password'
                        type='password'
                        required
                        message='Please enter your confirm password'
                        value={form.getFieldValue("confirmPass") || ''}
                        dependencies={['password']}
                        className='bg-main border0'
                        rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                              },
                            }),
                          ]}
                    />
                </Col>
                <Col span={24}>
                    <Flex justify='end'>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn"
                        >
                            Save Changes
                        </Button>
                    </Flex>
                </Col>
            </Row>
        </Form>
    </div>
  )
}

export {ChangePassword}