import React, { useState } from "react"
import { Drawer, Button, Avatar, List, theme, Typography, Flex} from "antd"
import "./index.css"
import {
    DeleteOutlined
} from '@ant-design/icons'
import { ActionButton } from "../../PageComponents/ActionButton";

const { useToken } = theme;
const { Text } = Typography


const NotificationsDrawer= ({visible, onClose})=>{
    const { token } = useToken();
    const [ closedraw, setCloseDraw ] = useState(false)
    const data = [
        {   
            img:'im-1.png',
            title: 'Josph Smart',
            desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer.",
            time:'12 days ago'
        },
        {
            img:'im-1.png',
            title: 'Sarah Beauty',
            desc: 'Took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.',
            time:'12 days ago'
        },
        {
            img:'im-1.png',
            title: 'Zainab Shabir',
            desc:"Remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
            time:'12 days ago'
        },
    ];
    return (
        <Drawer
            title={<Text className='text-white'>Notifications</Text>}
            onClose={onClose}
            open={visible}
            destroyOnClose
            width={500}
            className="card-bg"
            extra={
                <Button 
                    className="btn-sm bg-gradient border0 text-white"
                    onClick={() => setCloseDraw(true)}
                >
                    Clear All
                </Button>
            }
            footer={false}
        >

                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                        avatar={<Avatar src={'/assets/games/'+item?.img} />}
                        title={<a href="" className="text-white">{item?.title}</a>}
                        description={
                            <Flex vertical gap={'small'}>
                                <Text className='text-white'>{item?.desc}</Text>
                                <Text className='text-gray'>{item?.time}</Text>
                            </Flex>
                        }
                        className="text-white"
                        />
                        <div className="nofitication">
                            <ActionButton
                                icon={<DeleteOutlined className='text-white' />}
                                onClick={() => {}}
                                shape="circle"
                                ghost
                                className='borderwhite'
                            />
                        </div>
                    </List.Item>
                    )}
                />
        </Drawer>
    )
}
export default NotificationsDrawer