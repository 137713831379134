import React from 'react'
import { Button, Col, Flex, Form, Row, Typography } from 'antd'
import { MyInput } from '../../Forms'
import { PaperClipOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const Fa2Security = () => {

    const [form] = Form.useForm()

  return (
    <div className='py-3'>
        <Row gutter={[64,24]}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Flex vertical>
                    <Title level={4} className='text-white'>
                        Add Your Account
                    </Title>
                    <Text className='text-white py-2'>
                        Use the QR code or setup key on your Google Authenticator app to add your account.
                    </Text>
                    <Form
                        layout="vertical"
                    >
                        <MyInput
                            name='setupKey'
                            label='Setup Key'
                            placeholder='EH3KJPB7KB35C5MA'
                            suffix={<PaperClipOutlined className='text-white' />}
                            value={form.getFieldValue("setupKey") || ''} 
                            className='bg-main border0'
                        />
                    </Form>
                    <Text className='text-white'>
                        Google Authenticator is a multifactor app for mobile devices. It generates timed codes used during the 2-step verification process. To use Google Authenticator, install the Google Authenticator application on your mobile device
                    </Text>
                    <Flex className='py-3'>
                        <Button
                            type="primary"
                            className="btn"
                        >
                            Download
                        </Button>
                    </Flex>
                </Flex>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={()=>{}}
                >
                    <Row gutter={[12,8]}>
                        <Col span={24}>
                            <Title level={4} className='text-white'>
                                Add Your Account
                            </Title>
                        </Col>
                        <Col span={24}>
                            <MyInput
                                name='googleAuth'
                                label='Google Authenticator OTP'
                                required
                                message='Please enter your google authenticator OTP'
                                className='bg-main border0'
                                value={form.getFieldValue("googleAuth") || ''} 
                            />
                        </Col>
                        <Col span={24}>
                            <Flex>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="btn"
                                >
                                    Submit
                                </Button>
                            </Flex>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </div>
  )
}

export {Fa2Security}